/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Chip,
} from "@material-ui/core";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import VfSvgIcon from "../icons/VfSvgIcon";
import VfDialog from "../vf/VfDialog";
import WidgetHistory from "./WidgetsHistory";

const CardWidgetList = ({
  widget,
  cardAction,
  backAction,
  moreActions,
  isConfig,
  fetchHistory,
  clearHistory,
  showEnv,
  envType,
  envTitle,
  sourceEnvId,
  envs,
}) => {
  const [openDialogNotes, setOpenDialogNotes] = useState(false);
  const [openDialogHistory, setOpenDialogHistory] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = event => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleOpenDialogNotes = () => {
    setOpenDialogNotes(!openDialogNotes);
  };

  const handleOpenDialogHistory = () => {
    if (!openDialogHistory) fetchHistory();
    else clearHistory();
    setOpenDialogHistory(!openDialogHistory);
  };
  return (
    <Card
      elevation={0}
      className={classNames({
        "MuiCard-widget": true,
        "MuiCard-white": true,
        "MuiCard-shadowHover": true,
        "MuiCard-outlined": widget.isSaved,
        "MuiCard-outlined-error": false,
        "MuiCard-outlined-warning": false,
        "MuiCard-outlined-info": false,
        "MuiCard-outlined-success": widget.isSaved,
      })}
    >
      <Grid container alignItems="center">
        {backAction && (
          <Grid item xs="auto">
            <CardActions className="MuiCardActions-start">
              <IconButton edge="end" onClick={() => backAction()} data-testid="back_btn">
                <VfSvgIcon icon="arrowLeft" />
              </IconButton>
            </CardActions>
          </Grid>
        )}
        <Grid item xs>
          <CardActionArea onClick={cardAction} data-testid="widget_summery">
            <CardContent>
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs="auto">
                  <Grid container spacing={1}>
                    {isConfig && widget.componentId && (
                      <Grid item xs="auto">
                        <Typography
                          variant="body1"
                          component="span"
                          color="secondary"
                        >
                          {widget.widgetInstanceName || widget.widgetInstanceId}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs="auto">
                      <Typography variant="body1" component="h5"  data-testid="globalSettings_title">
                        {!isConfig
                          ? widget.description
                          : !widget.componentId
                          ? "Global Settings"
                          : widget.description}
                      </Typography>
                    </Grid>
                    {isConfig && widget.componentId && (
                      <Grid item xs="auto">
                        <Typography
                          variant="body1"
                          component="span"
                          color="secondary"
                        >
                          {widget.version}
                        </Typography>
                      </Grid>
                    )}
                    {widget.updatedAt && widget.user && (
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          {`Last edit: ${widget.updatedAt} - ${widget.user}`}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {showEnv && (
                  <Grid item xs="auto">
                    <Chip
                      /* eslint-disable */
                      icon={
                        <VfSvgIcon
                          className="MuiChip-leftIcon"
                          icon={envType}
                          fontSize="small"
                        />
                      }
                      className={classNames({
                        [`MuiChip-${envTitle.toLowerCase()}`]: true,
                        "MuiChip-selected": true,
                      })}
                      /* eslint-enable */
                      label={envTitle}
                      data-testid={`${envTitle}_btn`}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
        </Grid>
        {moreActions && (
          <Grid item xs="auto">
            <CardActions className="MuiCardActions-end">
              <IconButton
                edge="start"
                title="View readme file"
                onClick={handleClickMenu}
                data-testid="actionMenu_appConfiguration"
              >
                <VfSvgIcon icon="more" />
              </IconButton>
              <Menu
                id="simple-menu"
                elevation={0}
                anchorEl={anchorElMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorElMenu)}
                onClose={handleCloseMenu}
              >
                <MenuItem
                  onClick={() => {
                    handleOpenDialogNotes();
                    handleCloseMenu();
                  }}
                  data-testid="seeNotes_btn"
                >
                  See notes
                </MenuItem>
                {isConfig && (
                  <MenuItem
                    onClick={() => {
                      handleOpenDialogHistory();
                      handleCloseMenu();
                    }}
                    data-testid="history_btn"
                  >
                    History
                  </MenuItem>
                )}
                {envs
                  .filter(
                    ({ _id, isGlobal }) => !isGlobal && _id !== sourceEnvId
                  )
                  .sort((a, b) => (a.order < b.order ? -1 : 1))
                  .map(({ title }) => (
                    <MenuItem
                      key={title}
                      onClick={() => {
                        handleCloseMenu();
                      }}
                      className={`MuiListItem-${title.toLowerCase()}`}
                      data-testid={`Promoteto_${title}_btn`}
                    >
                      <ListItemText secondary={`Promote to ${title}`} />
                    </MenuItem>
                  ))}
              </Menu>
              <VfDialog
                openDialog={openDialogNotes}
                buttonCloseAction={handleOpenDialogNotes}
                title="Readme file"
                maxWidth="md"
                classDialogContent="MuiDialogContent-git"
              >
                <ReactMarkdown source={widget.readme} />
              </VfDialog>
              {isConfig && (
                <VfDialog
                  openDialog={openDialogHistory}
                  buttonCloseAction={handleOpenDialogHistory}
                  title="History"
                  maxWidth="md"
                >
                  <WidgetHistory />
                </VfDialog>
              )}
            </CardActions>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default CardWidgetList;
