/* eslint-disable camelcase */
import { call, put, takeLatest } from "redux-saga/effects";

import {
  // GET_LANGUAGES_REQUEST,
  // GET_LANGUAGES_REQUEST_SUCCESS,
  // GET_LANGUAGES_REQUEST_FAIL,
  GET_WIDGET_LANGUAGES_REQUEST,
  GET_WIDGET_LANGUAGES_REQUEST_SUCCESS,
  GET_WIDGET_LANGUAGES_REQUEST_FAIL,
  SAVE_WIDGET_TRANSLATIONS_REQUEST,
  SAVE_WIDGET_TRANSLATIONS_REQUEST_SUCCESS,
  SAVE_WIDGET_TRANSLATIONS_REQUEST_FAIL,
  TRANSLATION_WIDGET_LIST_REQUEST,
  TRANSLATION_WIDGET_LIST_REQUEST_SUCCESS,
  TRANSLATION_WIDGET_LIST_REQUEST_FAIL,
  PROMOTE_TRANSLATION_REQUEST,
  PROMOTE_TRANSLATION_REQUEST_SUCCESS,
  PROMOTE_TRANSLATION_REQUEST_FAIL,
} from "actions/translationsActions";
import translationsService from "services/TranslationsService";
import { SHOW_SNACKBAR } from "../actions/applicationsActions";

// function* getLanguages() {
//   try {
//     const { data } = yield call(translationsService.fetchlanguages);
//     yield put({
//       type: GET_LANGUAGES_REQUEST_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     yield put({ type: GET_LANGUAGES_REQUEST_FAIL, error });
//   }
// }

// function* getLanguagesWatcher() {
//   yield takeLatest(GET_LANGUAGES_REQUEST, getLanguages);
// }

function* getWidgetLanguages({ payload }) {
  try {
    const { data } = yield call(
      translationsService.fetchWidgetLanguages,
      payload
    );
    yield put({
      type: GET_WIDGET_LANGUAGES_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_WIDGET_LANGUAGES_REQUEST_FAIL, error });
  }
}

function* getWidgetLanguagesWatcher() {
  yield takeLatest(GET_WIDGET_LANGUAGES_REQUEST, getWidgetLanguages);
}

function* saveWidgetTranslations({ payload }) {
  try {
    const { data } = yield call(translationsService.saveTranslations, payload);
    yield put({
      type: SAVE_WIDGET_TRANSLATIONS_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: TRANSLATION_WIDGET_LIST_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    yield put({ type: SAVE_WIDGET_TRANSLATIONS_REQUEST_FAIL, error });
  }
}

function* saveWidgetTranslationsWatcher() {
  yield takeLatest(SAVE_WIDGET_TRANSLATIONS_REQUEST, saveWidgetTranslations);
}

function* translationWidgetListWorker({ payload }) {
  try {
    const { data,message } = yield call(
      translationsService.fetchTranslationWidget,
      payload
    );
    
    if(data){
      yield put({
          type: TRANSLATION_WIDGET_LIST_REQUEST_SUCCESS,
          payload: data,
        });
    }else{
      yield put({
        type: SHOW_SNACKBAR,
        payload: {
          show: true,
          message: message,
          severity: 0,
        },
      });
      yield put({ type: TRANSLATION_WIDGET_LIST_REQUEST_FAIL, error });

    }
 
  } catch (error) {
    console.log(error);
    yield put({ type: TRANSLATION_WIDGET_LIST_REQUEST_FAIL, error });
  }
}

function* translationWidgetListWatcher() {
  yield takeLatest(
    TRANSLATION_WIDGET_LIST_REQUEST,
    translationWidgetListWorker
  );
}

function* promoteTranslationWorker({ payload }) {
  try {
    const { data } = yield call(
      translationsService.promoteTranslations,
      payload
    );
    yield put({
      type: PROMOTE_TRANSLATION_REQUEST_SUCCESS,
      payload: data,
    });
    yield put({
      type: TRANSLATION_WIDGET_LIST_REQUEST,
      payload: {
        access_token: payload.access_token,
        id_token: payload.id_token,
      },
    });
  } catch (error) {
    console.log(error);
    yield put({ type: PROMOTE_TRANSLATION_REQUEST_FAIL, error });
  }
}

function* promoteTranslationWatcher() {
  yield takeLatest(PROMOTE_TRANSLATION_REQUEST, promoteTranslationWorker);
}

const translationsSaga = [
  // getLanguagesWatcher(),
  getWidgetLanguagesWatcher(),
  saveWidgetTranslationsWatcher(),
  translationWidgetListWatcher(),
  promoteTranslationWatcher(),
];

export default translationsSaga;
